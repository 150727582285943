module.exports = {
  comm: {
    home: '主頁',
    pricing: '定價',
    blog: '語音克隆&API',
    language: '語言：',
    select_language: '選擇語言',
    txt2voice: '文字轉語音',
    voice2txt: '語音轉文字',
    voiceclone: '語音克隆',
    video2txt: '視頻轉文字',
    footer_help: '需要幫助？聯繫我們：',
    footer_mail: 'tiktokaivoicetool@gmail.com',
    login: '登錄',
    login_desc: '登錄以訪問您的剪映 AI 配音帳戶',
    logout: '退出登錄',
  },
  txt2voice: {
    title: '在線免費文字轉語音:生成剪映AI配音',
    description: '免費合成剪映中的AI配音，任意文字轉語音的在線AI視頻配音助手，支持小帥，猴哥，熊二，紫薇，新聞腔等熱門AI配音',
    keywords: '文字轉語音，AI配音，視頻配音助手，剪映',
    main_title_p01: '文字轉語音助手-剪映AI配音神器',
    main_title_p02: '支持小帥，猴哥，熊二，紫薇，新聞腔等熱門AI配音',
    main_textarea_holder: '在此輸入或粘貼文本',
    main_genvoice: '合成',
    main_generating: '合成中',
    main_input_empty: '請輸入或粘貼文本',
    daily_usage_limit_msg: '1) 已達到今日最大使用次數，請明天再來。 2) 如果急需更多使用次數，請給我們發郵件。',
    text_max_prompt1: '請將字數限制在',
    text_max_prompt2: '字以內！',
    popup_nologin_title: '升級您的訂閱計劃',
    popup_nologin_desc: '通過升級到高級訂閱來解鎖更多語音生成時間。',
    popup_nologin_btntext: '探索訂閱計劃',
    popup_nosub_title: '升級您的訂閱計劃',
    popup_nosub_desc: '通過升級到高級訂閱來解鎖更多語音生成時間。',
    popup_nosub_btntext: '探索訂閱計劃',
    popup_sublimit_title: '訂閱已用盡，請升級',
    popup_sublimit_desc: '通過我們的高級功能和無限訪問來提升您的體驗。',
    popup_sublimit_btntext: '前往購買更多',
  },
  pricing: {
    new_features_alert: "📣 我們已經推出 🎙️ 語音克隆和 🤖 API 服務，供付費會員使用！(付款後，請發送電子郵件至 tiktokaivoicetool@gmail.com 獲取這些功能的幫助)。此外，隨時通過電子郵件與我們聯繫以獲取任何自定義請求😃",
    pricing_title: '從個人創作者到大型企業，我們為您提供合適的計劃。',
    pricing_desc: '100多種自然、類人的聲音。15種以上語言。幾乎是每個人的最低價格',
    sub_free_name: '免費',
    sub_free_desc: '適合想嘗試最先進AI音頻的個人',
    sub_free_content: [
      '每月1000字符限制',
      '每天5次生成',
    ],
    sub_starter_name: '入門版',
    sub_starter_desc: '適合為全球受眾製作優質內容的創作者',
    sub_starter_content: [
      '1 個語音克隆',
      '每月300000字符限制（約7小時音頻）',
      '無限下載',
      '72小時郵件支持',
      '優先訪問新聲音和功能',
    ],
    sub_pro_name: '專業版',
    sub_pro_desc: '適合加大內容製作的創作者',
    sub_pro_content: [
      'API 訪問',
      '3 個語音克隆',
      '每月1000000字符限制（約24小時音頻）',
      '無限下載',
      '48小時郵件支持',
      '優先訪問新聲音和功能',
    ],
    period_year: '年付',
    period_month: '月付',
    period_month_short: '月',
    billed_year: '按年計費',
    most_popular: '最受歡迎',
    discount_quantity: '節省$48',
    buy_btntext: '開始使用',
    pay_succ: '支付成功！',
    pay_succ_desc: '感謝您的購買。您的交易已成功完成。',
    pay_succ_btntext: '開始使用TikTok語音',
    pay_fail: '支付失敗！',
    pay_fail_desc: "我們很抱歉，但您的交易無法處理。請重試或聯繫我們的支持團隊尋求幫助，郵箱: tiktokaivoicetool@gmail.com。",
    pay_fail_btntext: '返回首頁',
  },
  setting: {
    setting: '設置',
    setting_title: '在這裡管理您的帳戶、使用情況和訂閱。',
    basic_info: '基本信息',
    user_name: '姓名',
    user_email: '郵箱',
    user_account: '帳戶',
    user_subscript: '當前計劃',
    user_usage: '使用情況',
    manage_subscript: '管理訂閱',
    use_limit_promote: "您已使用每月10000字符限制中的5000字符。",
    not_logged_in: '您尚未登錄',
    go_to_login: '前往登錄 >>',
  },
  login: {
    title: "抖音語音生成器",
    subtitle: "登錄以訪問您的抖音語音生成器帳戶",
    alreadyLoggedIn: "您已經登錄",
    goToHome: "返回首頁"
  },
  faq: {
    quest1: '剪映文字轉語音助手 - 最佳剪映AI配音軟件',
    answer1_1: "這是一個由最新的文字轉語音技術開發的剪映AI配音合成軟件，可以合成熱門的剪映AI配音和抖音音效，比如小帥，猴哥，熊二，舌尖上的中國，新聞腔等中文配音，另外，也支持熱門的剪映國際版AI配音，比如： Jessie語音（女性語音）、Siri 語音、鬼臉語音、C3PO 語音（機器人語音）、深沉語音（故事講述者）、Skye 語音、溫暖語音、Bestie 語音、英雄語音（Chris 聲音）、富有同情心的語音、嚴肅語音、Joey 語音、Stitch 語音、風暴兵語音（星球大戰）、Rocket 語音（銀河護衛隊）。",
    answer1_2: "即將支持的剪映國際版AI配音包括：Derek 語音、Trickster 語音、Austin Butler 語音、新聞播報員語音、Adam 語音、狗語音、Miley Cyrus 語音、Alexa 語音、外星人語音、動物語音、嬰兒語音、電腦語音、花栗鼠語音、回聲語音、NPR 語音。",
    answer1_3: '此外，剪映文字轉語音助手支持多種其他語言的AI配音，包括中文、日語、韓語、越南語、泰語、印地語、波斯語、俄語、德語、法語、羅馬尼亞語、捷克語、西班牙語、葡萄牙語、孟加拉語、意大利語、阿拉伯語、烏爾都語、繁體中文和馬來語。',
    answer1_4: '如果您急需某個特定中文配音或其他語言的AI配音，請發送電子郵件給我。',
    
    quest2: '剪映文字轉語音助手的優勢是什麼？',
    answer2_1: '- 剪映文字轉語音助手可以生成多種類型的語音，通常用於抖音視頻中。',
    answer2_2: "- 這是最新的AI配音軟件，可以生成接近人類的語音。",
    answer2_3: '- 在筆記本和台式電腦上進行視頻編輯更加方便。',
    answer2_4: "- 您可以使用一些剪映中無法找到的語音。",

    quest3: '如何使用剪映文字轉語音助手？',
    answer3_1: '剪映文字轉語音助手非常易於使用：',
    answer3_2: '- 選擇語言和語音口音。',
    answer3_3: '- 在文本框中輸入要轉換為語音的文字，',
    answer3_4: '- 按合成按鈕，等待幾秒鐘，',
    answer3_5: '- 播放或下載配音文件。',

    quest4: '最著名和最有趣的剪映AI配音是什麼？',
    answer4_1: '最著名的男性剪映配音，是小帥。',
    answer4_2: "對於最有趣的剪映配音，我個人推薦猴哥和熊二的配音。這些語音由於其高度識別性，經常用於搞笑的抖音視頻中，並能迅速讓觀眾沉浸其中。",
   
    quest5: '最煩人的剪映AI配音是什麼？',
    answer5_1: "我認為不存在真正'煩人'的剪映AI配音。",
    answer5_2: "更重要的是語音使用的上下文和內容，可能會讓某些人感到不舒服。例如，有些人可能會覺得小帥配音很煩，因為它在某一時期在抖音上出現得太頻繁了，比如你經常能聽到：注意看，這個男人叫小帥。",
    answer5_3: '所以，我建議大家放下顧慮和成見，多次聆聽本工具的語音，耐心找到最適合自己的剪映文字轉語音的效果。',

    quest6: '如何使用剪映文字轉語音助手合成猴哥的AI配音？',
    answer6_1: '- 在剪映文字轉語音助手的第一個下拉菜單中，選擇中文口音，然後在另一個下拉菜單中選擇猴哥的配音。',
    answer6_2: '- 將文本輸入輸入框中，然後點擊合成按鈕。',
    answer6_3: '- 等待大約十秒鐘，您將聽到AI合成的配音。在操作欄中，找到帶有向下箭頭的按鈕並點擊下載。',

    quest7: '如何使用剪映文字轉語音助手合成這個男人叫小帥的AI配音？',
    answer7_1: '- 選擇英語中文口音，然後選擇小帥。',
    answer7_2: '- 輸入文本並點擊合成按鈕。',
    answer7_3: '- 等待片刻，您將聽到AI配音。在操作欄中，找到帶有向下箭頭的按鈕並點擊下載。',

    quest8: '如何使用剪映文字轉語音助手合成熊二的AI配音？',
    answer8_1: '- 選擇中文，然後選擇熊二。',
    answer8_2: '- 輸入或粘貼文本並按合成語音按鈕。',
    answer8_3: '- 剪映文字轉語音助手就會立即合成配音，您可以播放它。',

    quest9: '如何使用剪映文字轉語音助手合成還珠格格中紫薇的AI配音？',
    answer9_1: '- 選擇中文，然後點擊紫薇的語音。',
    answer9_2: '- 將文本放入輸入框中並按生成語音按鈕。',
    answer9_3: '- 紫薇的AI語音將被合成。',

    quest10: '如何在抖音視頻中添加剪映AI配音？',
    answer10_1: '如果您想知道如何在抖音上使用官方剪映的語音，我會很快寫一篇專門的博客文章討論這個話題。',
    answer10_2: '在這裡，我將解釋如何在生成並下載語音後，將您的語音發布到抖音。',
    answer10_3: '1. 如果您已經在 PC 上生成了剪映AI配音並想將其上傳到抖音 或其他視頻編輯軟件到手機上，您需要將語音文件傳輸到手機。對於 iPhone，您可以使用 AirDrop 進行傳輸。對於安卓手機，我不太熟悉這個過程，但您肯定可以找到相應的方法和工具。',
    answer10_4: '2. 將語音文件傳輸到手機後，您可以打開抖音：',
    answer10_5: "- 點擊界面底部的 '+' 按鈕，選擇手機中的視頻。",
    answer10_6: '- 上傳視頻後，在屏幕右側找到一個方形圖標進入編輯頁面。',
    answer10_7: "- 在頁面底部找到'添加語音'按鈕，並點擊選擇剛剛傳輸的語音文件。",
    answer10_8: '- 在此界面中，您可以對視頻進行一些調整，然後點擊右上角的按鈕發布。',

    quest11: '剪映AI配音助手是免費嗎？',
    answer11: '是的，這是一個免費的剪映AI文字轉語音在線軟件，最好的視頻AI配音助手。',

    quest12: '在哪裡可以生成流行的TikTok語音到我的TikTok視頻中？',
    answer12: '您可以訪問 https://tiktokvoice.net/ 來將TikTok語音生成器中的語音應用到您的視頻中。'
  },
  host: 'https://tiktokvoice.net',
}; 